import Analytics from '@services/analytics/Analytics';

const scrollCache: string[] = [];

function getOffset(element: Element) {
    const rect = element.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
    };
}

export default function () {
    const article = document.querySelector('.js-text-content');
    if (article === null) {
        return;
    }

    const scrollDistance = window.scrollY + window.innerHeight;

    for (const point of getScrollPoints()) {
        if (!scrollCache.includes(point.identifier)) {
            if (scrollDistance >= getOffset(article).top + article.scrollHeight * point.modifier) {
                Analytics.triggerEvent(point.eventName, 'scroll');
                scrollCache.push(point.identifier);
            } else {
                break;
            }
        }
    }
}

function getScrollPoints() {
    return [
        {
            identifier: '50%',
            modifier: 0.5,
            eventName: 'Скролл до середины публикации',
        },
        {
            identifier: '100%',
            modifier: 1,
            eventName: 'Скролл до конца публикации',
        },
    ];
}
