import type {ReactElement} from 'react';

export default function PostsLoading(): ReactElement {
    return (
        <div className="texts-loading">
            <span />
            <span />
            <span />
        </div>
    );
}
