import type {ReactElement} from 'react';
import {StateProvider as SearchStateProvider} from '@components/Search/Context';
import Root from '@components/Search/Root';

export default function Search(): ReactElement {
    return (
        <SearchStateProvider>
            <Root />
        </SearchStateProvider>
    );
}
