import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import type {TransitionProps} from '@interfaces/Transition';

interface Props extends TransitionProps {
    modifier?: 'quick';
}

interface TimeoutDict {
    fade: number;
    'fade-quick': number;
    [key: string]: number;
}

const TIMEOUT: TimeoutDict = {
    fade: 200,
    'fade-quick': 100,
};

export default function Fade({modifier, ...rest}: Props) {
    const className = classNames({
        fade: !modifier,
        [`fade-${modifier}`]: modifier,
    });
    return <CSSTransition {...rest} classNames={className} timeout={TIMEOUT[className]} />;
}
