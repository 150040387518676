import type {ReactElement} from 'react';
import classNames from 'classnames';

interface Props {
    name: string;
    color: string;
}

export default function NewsMarker(props: Props): ReactElement<Props> | null {
    if (!props.name) {
        return null;
    }

    const className = classNames({
        'news-marker': !props.color,
        [`news-marker--${props.color}`]: props.color,
    });

    return <span className={className}>{props.name}</span>;
}
