import type {MouseEvent, ReactElement} from 'react';
import {useContext} from 'react';
import MoreButton from '@components/MoreButton';
import {store} from '@components/Search/Context';
import SearchError from '@components/Search/SearchError';
import StreamItem from '@components/StreamItem';
import type {SearchResult} from '@interfaces/Search';

interface Props {
    onLoadMoreClick(event: MouseEvent): void;
}

export default function SearchResults({onLoadMoreClick}: Props): ReactElement<Props> {
    const {
        currentState: {searchResults, isPageLoading, isMoreAvailable},
    } = useContext(store);
    return (
        <>
            {searchResults.length === 0 && (
                <SearchError
                    main={window.wp.i18n.__('MainSearchError', 'paperpress')}
                    secondary={window.wp.i18n.__('SecondarySearchError', 'paperpress')}
                />
            )}
            {searchResults.length > 0 &&
                searchResults.map((item: SearchResult) => (
                    <StreamItem
                        className="js-track-search-results-click"
                        key={item.url}
                        withTextMarker
                        time={parseInt(item.date, 10)}
                        title={item.title}
                        link={item.url}
                        textMainMarker={item.mainMarker}
                        textSubMarker={
                            item.subMarker && {
                                name: item.subMarker.paper_subtag_name,
                                url: item.subMarker.paper_subtag_url,
                            }
                        }
                        dateFormat="dd MMMM yyyy"
                    />
                ))}
            {isMoreAvailable && (
                <div className="search-overlay__more">
                    <MoreButton loading={isPageLoading} onClick={onLoadMoreClick} />
                </div>
            )}
        </>
    );
}
