import type {HTMLProps, MouseEvent, ReactElement, ReactNode} from 'react';

interface Props extends HTMLProps<HTMLButtonElement> {
    children: ReactNode;
    colorModifier?: string;
    className?: string;
    type?: 'button' | 'reset' | 'submit' | undefined;
    onClick?(event: MouseEvent): void;
}

export default function ButtonOutline(props: Props): ReactElement {
    const {colorModifier, className, children, ...rest} = props;
    const baseClass = colorModifier ? `button-outline--${colorModifier}` : 'button-outline';
    return (
        <button className={`${baseClass} ${className}`} {...rest}>
            {children}
        </button>
    );
}

ButtonOutline.defaultProps = {
    className: '',
};
