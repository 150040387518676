import type {ChangeEvent, ReactElement} from 'react';
import {useState, useRef, useContext, useEffect} from 'react';
import search from '@api/search';
import Fade from '@components/Animation/Fade';
import {actions, store} from '@components/Search/Context';
import Overlay from '@components/Search/Overlay';
import SearchButton from '@components/Search/SearchButton';
import OverlayContext from '@context/OverlayContext';
import Analytics from '@services/analytics/Analytics';

const SEARCH_DELAY = 700;

export default function Root(): ReactElement {
    const {
        currentState: {currentPage, searchString},
        dispatch,
    } = useContext(store);

    const buttonRef = useRef<HTMLButtonElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [timerId, setTimerId] = useState<number>();

    useEffect(() => {
        OverlayContext.registerListener(state => setIsOpen(state.isSearchOpen));
    }, []);

    function handleClick() {
        if (isOpen) {
            clearTimeout(timerId);
            setTimerId(undefined);
            Analytics.triggerEvent('Закрытие блока поиска', 'click');
        } else {
            dispatch({type: actions.resetSearchState});
            Analytics.triggerEvent('Открытие блока поиска', 'click');
        }

        // this is needed so that the search button will not preserve its outline after being clicked
        if (buttonRef.current) {
            buttonRef.current.blur();
        }
        void OverlayContext.dispatch(OverlayContext.actions.toggleSearch);
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const currentValue = event.currentTarget.value;
        clearTimeout(timerId);

        dispatch({type: actions.changeSearchString, data: {value: currentValue}});

        setTimerId(
            setTimeout(() => {
                if (!currentValue) {
                    return;
                }

                dispatch({type: actions.startSearch});
                Analytics.triggerEvent('Поиск', 'search', {currentValue});

                search(currentValue, currentPage)
                    .then(data => {
                        dispatch({type: actions.setSearchResults, data});
                    })
                    .catch(() => dispatch({type: actions.setSearchError}));
                setTimerId(0);
            }, SEARCH_DELAY) as unknown as number,
        );
    }

    function handleLoadMoreClick() {
        dispatch({type: actions.startNextPageLoad});
        search(searchString, Number(currentPage) + 1)
            .then(data => {
                dispatch({type: actions.addSearchResultsPage, data});
            })
            .catch(() => dispatch({type: actions.setNextPageLoadError}));
    }

    return (
        <>
            <SearchButton onClick={handleClick} isSearchOpen={isOpen} buttonRef={buttonRef} />
            <Fade modifier="quick" in={isOpen} unmountOnExit>
                <Overlay onChange={handleChange} onLoadMoreClick={handleLoadMoreClick} />
            </Fade>
        </>
    );
}
