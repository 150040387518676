import type {MouseEvent, ReactElement} from 'react';
import PostsLoading from '@components/PostsLoading';
import ButtonOutline from '@components/ButtonOutline';

interface Props {
    loading: boolean;
    onClick(event: MouseEvent): void;
}

export default function MoreButton(props: Props): ReactElement {
    if (props.loading) {
        return <PostsLoading />;
    }
    return (
        <ButtonOutline className="more-button" onClick={props.onClick}>
            {window.wp.i18n.__('MoreButtonLabel', 'paperpress')}
        </ButtonOutline>
    );
}
