import Context from '@context/Context';

interface OverlayState {
    isMenuOpen: boolean;
    isSearchOpen: boolean;
}

class OverlayContext extends Context<OverlayState> {
    public readonly actions = {
        openMenu: 'openMenu',
        closeMenu: 'closeMenu',
        toggleSearch: 'toggleSearch',
    };

    private readonly initialState: OverlayState;

    public constructor(initialState: OverlayState) {
        super(initialState);
        this.initialState = initialState;
    }

    protected reduce(action: string): Promise<OverlayState> | OverlayState {
        switch (action) {
            case this.actions.openMenu:
                return {
                    isMenuOpen: true,
                    isSearchOpen: false,
                };
            case this.actions.closeMenu:
                return {
                    isMenuOpen: false,
                    isSearchOpen: this.state.isSearchOpen,
                };
            case this.actions.toggleSearch:
                return {
                    isMenuOpen: false,
                    isSearchOpen: !this.state.isSearchOpen,
                };
            default:
                throw new Error('Unknown action');
        }
    }

    public resetStore() {
        this.state = {...this.initialState};
        this.listeners = [];
    }
}

const context = new OverlayContext({
    isMenuOpen: false,
    isSearchOpen: false,
});
export default context;
