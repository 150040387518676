import {STATUS_REQUEST_ERROR} from '@constants/http';
import PaperError from '@errors/PaperError';

export const NAME = 'ApiError';

interface ApiErrorPayload {
    [key: string]: string;
}

export default class ApiError extends PaperError {
    public readonly payload: ApiErrorPayload;
    public readonly status: number;
    public readonly endpoint: string;

    public constructor(message = '', payload = {}, status = STATUS_REQUEST_ERROR, endpoint = '') {
        super(message, NAME);
        this.payload = payload;
        this.status = status;
        this.endpoint = endpoint;
    }
}
