import RequestClass from '@api/request/RequestClass';
import {API_NAMESPACE} from '@constants/general';
import type {GetStripsInterestResponse, SearchResults} from '@interfaces/Search';

export default async function search(query: string, page: number): Promise<SearchResults> {
    return (await new RequestClass(window.bootstrapParams.searchEndpoint)
        .setParams({search: query, page})
        .setAttemptsNumber(1)
        .send()) as Promise<SearchResults>;
}

export async function getStripsInterest(): Promise<GetStripsInterestResponse> {
    return (await new RequestClass(
        `${API_NAMESPACE}/strips/interest`,
    ).send()) as Promise<GetStripsInterestResponse>;
}
