import type {ReactElement} from 'react';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import Link from '@components/Link';
import MainMarker from '@components/Marker/MainMarker';
import SubMarker from '@components/Marker/SubMarker';
import NewsMarker from '@components/NewsMarker';
import type {
    GenericTextMarker,
    NewsMarker as NewsMarkerType,
    TextMarkerWithUrl,
} from '@interfaces/Post';
import {unixTimestampToMilli} from '@utils/dateUtils';

interface Props {
    className: string;
    time: number;
    title: string;
    link: string;
    newsMarker?: NewsMarkerType;
    textMainMarker?: GenericTextMarker;
    textSubMarker?: TextMarkerWithUrl;
    dateFormat: string;
    withTextMarker: boolean;
}

export default function StreamItem(props: Props): ReactElement<Props> {
    return (
        <div className={`stream-item ${props.className}`}>
            <div className="stream-item__info">
                <span className="time">
                    {format(new Date(unixTimestampToMilli(props.time)), props.dateFormat, {
                        locale: ru,
                    })}
                </span>
                {props.withTextMarker && props.textMainMarker && (
                    <MainMarker {...props.textMainMarker} className="stream-item__marker" />
                )}
                {props.withTextMarker && props.textSubMarker && (
                    <SubMarker {...props.textSubMarker} className="stream-item__marker" />
                )}
                {!props.withTextMarker && props.newsMarker && <NewsMarker {...props.newsMarker} />}
            </div>
            <Link to={props.link} className="stream-item__title">
                {props.title}
            </Link>
        </div>
    );
}

StreamItem.defaultProps = {
    className: '',
    dateFormat: 'HH:mm',
};
