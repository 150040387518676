import type {UserBlockProps} from '@interfaces/Profile';
import type {ReactElement} from 'react';
import TextButton from '@components/Button/TextButton';

export default function UserBlockFallback(props: UserBlockProps): ReactElement<UserBlockProps> {
    return (
        <div className="user-block__form--sparse">
            <div className="user-block__email">{props.initialEmail}</div>
            <div className="user-block__password">
            <TextButton>
                    {window.wp.i18n.__('Profile_userBlock_changePassword', 'paperpress')}
                </TextButton>
            </div>
            <TextButton disabled>
                {window.wp.i18n.__('Profile_userBlock_save', 'paperpress')}
            </TextButton>
        </div>
    );
}
