export const STATUS_OK = 200;
export const STATUS_NO_CONTENT = 204;
export const STATUS_REQUEST_ERROR = 400;
export const STATUS_PAYMENT_REQUIRED = 402;
export const STATUS_SERVER_ERROR = 500;

export const METHOD_GET = 'GET';
export const METHOD_POST = 'POST';
export const METHOD_PUT = 'PUT';
export const METHOD_DELETE = 'DELETE';
