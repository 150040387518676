import type {ReactNode, ReactPortal} from 'react';
import {createPortal} from 'react-dom';
import {POPUP_CONTAINER_ID} from '@constants/general';
import PaperError from '@errors/PaperError';

interface Props {
    children: ReactNode;
}

function getContainer(): HTMLElement {
    const container = document.getElementById(POPUP_CONTAINER_ID);
    if (container === null) {
        throw new PaperError('Popup mount point is not found');
    }

    return container;
}

export default function Popup(props: Props): ReactPortal {
    return createPortal(props.children, getContainer());
}
