document.addEventListener('content-load', function() {
    for (const accordionHeader of document.querySelectorAll('.js-accordion-clickable')) {
        accordionHeader.addEventListener('click', toggleAnswer);
    }
});

interface ProperEventTarget extends EventTarget {
    parentNode: HTMLElement;
    contains(arg0: Node): boolean;
    closest(selector: string): HTMLElement;
}

function toggleAnswer(event: Event) {
    const target = getEventTarget(event);

    const selection = getSelection();

    if (
        selection &&
        selection.toString().length > 0 &&
        target.contains(selection.anchorNode as Node)
    ) {
        // if there is text selection - do nothing
        return;
    }
    const accordion = target.closest('.js-accordion');
    const contents = accordion.querySelector('.js-accordion-content');

    if (!contents) {
        return;
    }

    accordion.classList.toggle('closed');
    const htmlContents = contents as HTMLElement;
    if (htmlContents.style.maxHeight) {
        htmlContents.style.removeProperty('max-height');
    } else {
        htmlContents.style.maxHeight = `${htmlContents.scrollHeight}px`;
    }
}

function getEventTarget(event: Event): ProperEventTarget {
    if (event.currentTarget === null) {
        throw new Error("Event doesn't have a target");
    }
    return event.currentTarget as ProperEventTarget;
}
