import format from 'date-fns/format';

const TIMESTAMP_UNIX_TO_MILLI_MODIFIER = 1000;
const TIMESTAMP_MILLI_TO_UNIX_MODIFIER = 0.001;

export const TEN_MINUTES = 10 * 60;

export function milliTimestampToHumanReadable(date: number): string {
    return format(date, 'dd.MM.y');
}

export function unixTimestampToHumanReadable(date: number): string {
    return milliTimestampToHumanReadable(unixTimestampToMilli(date));
}

export function unixTimestampToMilli(timestamp: number): number {
    return timestamp * TIMESTAMP_UNIX_TO_MILLI_MODIFIER;
}

export function milliTimestampToUnix(timestamp: number): number {
    return Math.trunc(timestamp * TIMESTAMP_MILLI_TO_UNIX_MODIFIER);
}

export function milliTimestampToSqlDateTimeString(timestamp: number): string {
    return format(timestamp, 'y-MM-dd HH:mm:ss');
}

export function unixTimestampToSqlDateTimeString(timestamp: number): string {
    return milliTimestampToSqlDateTimeString(unixTimestampToMilli(timestamp));
}

export function getTomorrowStartDate(): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrowStartDate = new Date(today);
    tomorrowStartDate.setDate(today.getDate() + 1);
    return tomorrowStartDate;
}
