let identifier: string;

function initialize(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function getIdentifier(): string {
    if (!identifier) {
        identifier = initialize(8);
    }
    return identifier;
}
