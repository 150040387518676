import OverlayContext from '@context/OverlayContext';
import type {ChangeEvent, MouseEvent, ReactElement} from 'react';
import {useEffect, useRef, useContext, useState} from 'react';
import Popup from '@components/Popup';
import {store} from '@components/Search/Context';
import SearchError from '@components/Search/SearchError';
import SearchResults from '@components/Search/SearchResults';

interface Props {
    onChange(event: ChangeEvent): void;
    onLoadMoreClick(event: MouseEvent): void;
}

const TOP_MENU_OFFSET = 56;

export default function Overlay({onChange, onLoadMoreClick}: Props): ReactElement<Props> {
    const {currentState} = useContext(store);
    const [verticalOffset, setVerticalOffset] = useState(0);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
        updateVerticalOffset();
    }, []);

    useEffect(() => {
        document.addEventListener('top-banner-change', updateVerticalOffset);
        return () => document.removeEventListener('top-banner-change', updateVerticalOffset);
    }, []);

    function updateVerticalOffset() {
        setVerticalOffset(
            (document.querySelector('.header') as HTMLDivElement).getBoundingClientRect().top +
                TOP_MENU_OFFSET,
        );
    }

    function handleClose() {
        void OverlayContext.dispatch(OverlayContext.actions.toggleSearch);
    }

    return (
        <Popup>
            <div className="search-overlay" style={{top: verticalOffset}}>
                <div className="search-overlay__top">
                    <input
                        type="text"
                        className="search-overlay__input"
                        ref={inputRef}
                        onChange={onChange}
                        value={currentState.searchString}
                    />
                    <button className="search-overlay__close" onClick={handleClose}>
                        <div className="search-overlay__cross" />
                    </button>
                </div>
                <div className="search-overlay__body">
                    <div className="search-overlay__content">
                        {currentState.isError && (
                            <SearchError
                                main={window.wp.i18n.__('ShortError', 'paperpress')}
                                secondary={window.wp.i18n.__('ErrorSecondPart', 'paperpress')}
                            />
                        )}
                        {currentState.searchString.length > 0 &&
                            currentState.hasSearched &&
                            !currentState.isLoading &&
                            !currentState.isError && (
                                <div className="search-overlay__results">
                                    <SearchResults onLoadMoreClick={onLoadMoreClick} />
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </Popup>
    );
}
