import type {HTMLProps, MouseEvent, ReactNode} from 'react';

interface Props extends HTMLProps<HTMLButtonElement> {
    colorModifier?: string;
    children: ReactNode;
    type?: 'button' | 'reset' | 'submit' | undefined;
    onClick?(event: MouseEvent): void;
}

export default function TextButton({colorModifier, className, children, ...rest}: Props) {
    const baseClass = colorModifier ? `button-text--${colorModifier}` : 'button-text';
    return (
        <button className={`${baseClass} ${className}`} {...rest}>
            {children}
        </button>
    );
}

TextButton.defaultProps = {
    className: '',
};
