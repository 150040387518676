import type {ReactElement} from 'react';

interface Props {
    main: string;
    secondary?: string;
}

export default function SearchError({main, secondary}: Props): ReactElement<Props> {
    return (
        <>
            <p className="search-error__main">{main}</p>
            {secondary && <p className="search-error__secondary">{secondary}</p>}
        </>
    );
}
