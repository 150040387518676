import type {ReactElement} from 'react';
import classNames from 'classnames';
import Link from '@components/Link';

interface Props {
    name: string;
    url: string;
    modifier?: string;
    className: string;
    linkClassName: string;
    ioName?: string;
}

export default function TextMarker(props: Props): ReactElement<Props> | null {
    if (!props.name) {
        return null;
    }

    const classes = classNames({
        [props.linkClassName]: props.linkClassName,
        'text-marker': !props.modifier,
        [`text-marker--${props.modifier}`]: props.modifier,
    });

    return (
        <div
            className={props.className}
            data-vr-contentbox={props.ioName}
            data-vr-contentbox-url={props.url}
        >
            <Link to={props.url} className={classes} data-vr-headline>
                {props.name}
            </Link>
        </div>
    );
}

TextMarker.defaultProps = {
    className: '',
    linkClassName: '',
};
