import type ValueList from '@interfaces/ValueList';
import type {ReactElement, ReactNode, Reducer} from 'react';
import {createContext, useReducer} from 'react';
import type ReducerAction from '@interfaces/ReducerAction';
import type {
    ChangeSearchStringReducerAction,
    SearchContext,
    SearchState,
    SetInterestLoadedReducerAction,
    SetSearchResultsReducerAction,
} from '@interfaces/Search';

interface Props {
    children: ReactNode;
}

const actions: ValueList<string> = {
    setInterestLoading: 'setInterestLoading',
    setInterestError: 'setInterestError',
    setInterestLoaded: 'setInterestLoaded',
    changeSearchString: 'changeSearchString',
    startSearch: 'startSearch',
    setSearchResults: 'setSearchResults',
    setSearchError: 'setSearchError',
    startNextPageLoad: 'startNextPageLoad',
    addSearchResultsPage: 'addSearchResultsPage',
    setNextPageLoadError: 'setNextPageLoadError',
    resetSearchState: 'resetSearchState',
};

const initialState: SearchState = {
    interestState: 'initial',
    interest: [],
    isLoading: false,
    isPageLoading: false,
    isMoreAvailable: false,
    isError: false,
    hasSearched: false,
    searchString: '',
    searchResults: [],
    currentPage: 1,
};

const store = createContext<SearchContext>({
    currentState: initialState,
    dispatch: () => {
        /* do nothing */
    },
});
const {Provider} = store;

const reducer: Reducer<SearchState, ReducerAction> = (state, action) => {
    switch (action.type) {
        case actions.setInterestLoading:
            return {
                ...state,
                interestState: 'loading',
            };
        case actions.setInterestError:
            return {
                ...state,
                interestState: 'error',
            };
        case actions.setInterestLoaded:
            return {
                ...state,
                interestState: 'loaded',
                interest: (action as SetInterestLoadedReducerAction).data,
            };
        case actions.resetSearchState:
            return initialState;
        case actions.changeSearchString:
            return {
                ...state,
                isError: false,
                isPageLoading: false,
                searchString: (action as ChangeSearchStringReducerAction).data.value,
                currentPage: 1,
            };
        case actions.startSearch:
            return {
                ...state,
                hasSearched: true,
                isLoading: true,
            };
        case actions.setSearchError:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actions.setSearchResults:
            return {
                ...state,
                isLoading: false,
                searchResults: (action as SetSearchResultsReducerAction).data,
                isMoreAvailable: (action as SetSearchResultsReducerAction).data.length > 0,
            };
        case actions.startNextPageLoad:
            return {
                ...state,
                isPageLoading: true,
            };
        case actions.setNextPageLoadError:
            return {
                ...state,
                isPageLoading: false,
                isMoreAvailable: false,
            };
        case actions.addSearchResultsPage:
            return {
                ...state,
                isPageLoading: false,
                searchResults: [
                    ...state.searchResults,
                    ...(action as SetSearchResultsReducerAction).data,
                ],
                currentPage: state.currentPage + 1,
                isMoreAvailable: (action as SetSearchResultsReducerAction).data.length > 0,
            };
        default:
            throw new Error('Unknown action');
    }
};

const StateProvider = function ({children}: Props): ReactElement<Props> {
    const [currentState, dispatch] = useReducer(reducer, initialState);
    return <Provider value={{currentState, dispatch}}>{children}</Provider>;
};

export {store, StateProvider, actions};
