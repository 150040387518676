import NonDisplayableError from '@errors/NonDisplayableError';
import type RestApiResponse from '@interfaces/RestApiResponse';
import isObject from 'lodash.isobject';
import Reattempt from 'reattempt';
import ApiError from '@errors/ApiError';
import {
    METHOD_DELETE,
    METHOD_GET,
    STATUS_NO_CONTENT,
    STATUS_REQUEST_ERROR,
    STATUS_SERVER_ERROR,
} from '@constants/http';
import PaperError from '@errors/PaperError';
import type {HttpMethod} from '@interfaces/Http';
import type ValueList from '@interfaces/ValueList';

interface RequestParams {
    resource: RequestInfo;
    init: RequestInit;
}

interface InternalResponse {
    status: number;
    payload?: unknown;
}

const DEFAULT_ATTEMPTS_NUMBER = 3;

export default class RequestClass {
    private path: string;
    private method: HttpMethod;
    private params: ValueList;
    private headers: ValueList<string>;
    private attempts: number;

    public constructor(path = '', method: HttpMethod = METHOD_GET) {
        this.path = path;
        this.method = method;
        this.params = {};
        this.headers = {};
        this.attempts = DEFAULT_ATTEMPTS_NUMBER;
    }

    public setPath(path: string): this {
        this.path = path;
        return this;
    }

    public setMethod(method: HttpMethod): this {
        this.method = method;
        return this;
    }

    public setParams(params: {}): this {
        this.params = {...this.params, ...params};
        return this;
    }

    public setParam(name: string, value: unknown): this {
        this.params[name] = value;
        return this;
    }

    public setHeader(name: string, value: string): this {
        this.headers[name] = value;
        return this;
    }

    public setAuthHeader(token: string): this {
        this.headers.Authorization = `Bearer ${token}`;
        return this;
    }

    public setVersionHeader(version: number): this {
        this.headers['Api-Version'] = String(version);
        return this;
    }

    public setAttemptsNumber(attempts: number): this {
        this.attempts = attempts;
        return this;
    }

    public async send(): Promise<unknown> {
        const {resource, init} = this.prepareRequest();
        return Reattempt.run({times: this.attempts}, async () => this.doSend(resource, init)).then(
            ({status, payload}: InternalResponse) => {
                if (status >= STATUS_REQUEST_ERROR) {
                    let errorMessage = '';
                    let errorData;

                    if (isObject(payload)) {
                        errorMessage = (payload as RestApiResponse).message || '';
                        errorData = (payload as RestApiResponse).data as {} | undefined;
                    } else if (typeof payload === 'string') {
                        errorMessage = payload;
                    }

                    throw new ApiError(errorMessage, errorData, status, this.path);
                }

                return payload;
            },
        );
    }

    public sendBlind() {
        const {resource, init} = this.prepareRequest();
        void fetch(resource, init);
    }

    private prepareRequest(): RequestParams {
        if (!this.path) {
            throw new PaperError('Request path is not specified');
        }

        return {
            resource: this.getFullUrl(),
            init: {
                method: this.method,
                credentials: 'same-origin',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    ...this.headers,
                },
                ...(this.method !== METHOD_GET &&
                    this.method !== METHOD_DELETE && {body: JSON.stringify(this.params)}),
            },
        };
    }

    private getFullUrl(): string {
        let fullUrl = this.path;

        if (this.method === METHOD_GET || this.method === METHOD_DELETE) {
            const optionsString = this.getOptionsString();
            if (optionsString.length > 0) {
                fullUrl = `${fullUrl}?${optionsString}`;
            }
        }
        return fullUrl;
    }

    private getOptionsString(): string {
        let result = '';

        for (const key of Object.keys(this.params)) {
            if (typeof this.params[key] === 'undefined') {
                continue;
            }

            result = `${result}&${key}=${this.params[key]}`;
        }

        return result.substring(1);
    }

    private async doSend(resource: RequestInfo, init: RequestInit): Promise<InternalResponse> {
        return fetch(resource, init).then(async response => {
            if (response.status >= STATUS_SERVER_ERROR) {
                throw new NonDisplayableError(
                    `Request to ${this.path} ended with an error (response status ${response.status})`,
                );
            }

            if (response.status === STATUS_NO_CONTENT) {
                return Promise.resolve({
                    status: response.status,
                });
            }

            return response
                .json()
                .catch(error => console.error(error))
                .then(payload => ({
                    status: response.status,
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    payload,
                }));
        });
    }
}
